import * as React from "react"
import withLayout from "../hoc/withLayout";
import Yandex from "../components/Yandex";
import ComplexColumns from "../components/ComplexColumns";
import UTMHeader from "../components/UTMHeader";
import SelectComplexQuiz from "../components/SelectComplexQuiz";
import OgImage from "../images/og-image.jpg";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { ServiceNav } from "../components/ServiceNav";
import "./index.scss";

// markup
const IndexPage = () => {
  return (
    <>
      <Header/>
        <main>
          <UTMHeader/>
          <ComplexColumns />
          <ServiceNav/>
          <Yandex/>
        </main>
      <Footer/>
    </>
  )
}

export default withLayout(IndexPage,
  "Студия детейлинга AutoZorgo в Минске",
  "Химчистка автомобилей, полировка, оклейка плёнкой, комплексы услуг, керамические покрытия!",
  OgImage
)
