import * as React from "react";
import Container from "react-bootstrap/Container";


const SelectComplexQuiz = () => {
    return (
        <div style={{backgroundColor: "#f5f5f5"}}>
            <Container className="d-flex justify-content-center align-items-center flex-column">
                <a href="https://www.testwizard.ru/test.php?id=135998" className=" mt-4 btn btn-lg btn-primary" target="_blank" rel="noreferrer">Выбрать комплекс</a>
                <p className="fw-normal fs-5 mt-4 mb-5 text-center">Ответь всего на 5 вопросов и узнай, какой комплекс лучше всего подойдет для твоего автомобиля!</p>
            </Container>
        </div>
    )
}

export default SelectComplexQuiz;