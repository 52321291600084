import React from "react";
import PropTypes from "prop-types";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import {QuestionCircleFill} from "react-bootstrap-icons";
import {trackEvent} from "../common";

function ComplexColumnHelpButton({complexTitle, popoverBody}) {
  const [show, setShow] = React.useState(false);
  const target = React.useRef(null);

  const handleClick = () => {
    if (!show) {
      trackEvent("Popover", "Show", complexTitle, true)
    }
    setShow(!show);
  }

  return (
    <>
      <button ref={target} type={"button"} className="popover-icon" onClick={handleClick} onBlur={() => setShow(false)}>
        <QuestionCircleFill/>
      </button>

      <Overlay target={target} show={show} placement="bottom">
        <Popover>
          <Popover.Body>{popoverBody}</Popover.Body>
        </Popover>
      </Overlay>
    </>
  )
}

export default ComplexColumnHelpButton;

ComplexColumnHelpButton.propTypes = {
  complexTitle: PropTypes.node.isRequired,
  popoverBody: PropTypes.node.isRequired,
}