import React from "react";
import {getUTM} from "../common";
import Container from "react-bootstrap/Container";

function getHeader() {
  const utm = getUTM();
  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "geo") {
    return {
      header: "Детейлинг мойка автомобиля в Минске на Шаранговича от 40 BYN",
      description: "Скорость работы от 1 часа - в зависимости от выбора комплекса услуг"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "tsennost") {
    return {
      header: "Хотите продать автомобиль, не снижая на него цену?",
      description: "Предлагаем комплекс \"Межсезонный. Осень\", который даст максимальный эффект при минимальных затратах"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "kakoy") {
    return {
      header: "Приведем в порядок автомобиль и защитим гидрофобным составом Ceramic Pro SPORT",
      description: ""
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "chto") {
    return {
      header: "Качественный детейлинг от профессионалов своего дела",
      description: "Моментальная консультация и рекомендации по уходу за автомобилем. Звоните!"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "25-34") {
    return {
      header: "Ухаживайте за автомобилем раз в месяц с комплексом услуг пакета \"Ежемесячный CHECK_UP\"",
      description: "Презентабельный внешний вид авто в одном комплексе"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "35-44") {
    return {
      header: "Профессиональный детейлинг автотранспорта по фиксированной цене",
      description: "Специально разработанные комплексы по уходу за авто Autozorgo экономят деньги и время"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "sport") {
    return {
      header: "Предлагаем качественный детейлинг с сохранением эстетического здоровья авто",
      description: "Только сертифицированная автохимия"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "business") {
    return {
      header: "Хотите просто привести внешний вид авто в порядок с сохранением времени и денег?",
      description: "Комплексы для ухода за машиной разработали для вас опытные детейлеры Autozorgo. Здесь есть то, что вы искали"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "transport") {
    return {
      header: "Закажите детальную мойку",
      description: "С использованием кистей для труднодоступных мест или комплексы детейлинга, которые нужны именно сегодня"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "premium" && utm.utm_content === "razvlecheniya_i_dosug") {
    return {
      header: "Жми \"Записаться\" на комплекс по уходу за автомобилем \"Межсезонный. Осень\"",
      description: "Получи детальный уход за авто с приданием гидрофобного эффекта Ceramic Pro"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "middle" && utm.utm_content === "geo") {
    return {
      header: "Профессиональные и качественные услуги детейлинга в Сухарево",
      description: ""
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "middle" && utm.utm_content === "tsennost") {
    return {
      header: "Услуги детейлинга по фиксированным ценам",
      description: "Все необходимое в одном комплексе"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "middle" && utm.utm_content === "health and beauty") {
    return {
      header: "Самые необходимые комплексы детейлинга",
      description: "Чтобы сохранить эстетическое здоровье автомобиля"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "middle" && utm.utm_content === "gender_and_age_2434") {
    return {
      header: "Выберите любой из подходящих комплексов",
      description: "Все самое необходимое в одном"
    }
  }

  if (utm.utm_source === "yandex" && utm.utm_medium === "cpc" && utm.utm_campaign === "middle" && utm.utm_content === "gender_and_age_3444") {
    return {
      header: "Трехфазная мойка с чисткой ковриков от 40 BYN",
      description: "Деликатный и профессиональный подход"
    }
  }

  return {
    header: "Студия детейлинга в Минске",
    description: "Деликатный и профессиональный подход"
  }
}

function UTMHeader() {
  const header = getHeader();
  return (
    <div className="utm-header">
      {
        header.header &&
        <Container>
          <h1 style={{marginBottom: 0}}>{header.header}</h1>
          {
            header.description &&
            <p>{header.description}</p>
          }
        </Container>
      }
    </div>
  )
}

export default UTMHeader;