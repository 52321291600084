import React from "react";
import Accordion from "react-bootstrap/Accordion";
import LogoSvg from "../images/logo.svg";
import {contacts, trackEvent } from "../common";
import {Instagram} from "react-bootstrap-icons";
import TrackLink from "./TrackLink";
import HeaderContacts from "./HeaderContacts";

function HeaderSmall() {
  const handleClickAccordionHeader = () => {
    trackEvent("ContactsAccordion", "Click", "", true);
  }

  return (
    <header className={"header-small"}>
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header onClick={handleClickAccordionHeader}>
            <span className="inner">
              <span className="title">Детейлинг студия - контакты</span>
              <small>{contacts.address[1]}</small>
            </span>
          </Accordion.Header>
          <Accordion.Body>
            <HeaderContacts/>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <div className={"header-logo"}>
        <img src={LogoSvg} alt={"Логотип Autozorgo"} className={"img-fluid"}/>
          <TrackLink href={"https://www.instagram.com/autozorgo_detailing/"}
                     className="instagram"
                     track={["Social", "Click", "Instagram", true]}
                     targetBlank
          >
            <Instagram/> {" "} Instagram
          </TrackLink>
      </div>
    </header>
  );
}

export default HeaderSmall;