import * as React from "react";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import {complexesCheckList} from "../common";
import SvgVisa from "../images/visa.svg";
import SvgMastercard from "../images/mastercard.svg";
import ComplexColumnModal from "./ComplexColumnModal";
import ComplexColumnPrice from "./ComplexColumnPrice";
import ComplexColumnHelpButton from "./ComplexColumnHelpButton";

export function ComplexColumn({
                                title,
                                time,
                                price,
                                activePriceKey,
                                changeActivePriceKey,
                                checkList,
                                bonus,
                                hit,
                                tabIndex
                              }) {
  const refCol = React.useRef(null);

  React.useEffect(() => {
    if (hit) {
      refCol.current.focus();
    }
  }, [hit]);

  let columns;
  let timeoutMouseOver = 0;
  let liHovered = null;
  const handleLiMouseOver = (e, idx) => {
    if (!columns || columns.length !== 6) {
      columns = e.target.parentNode.parentNode.parentNode.childNodes;
      return;
    }

    window.clearTimeout(timeoutMouseOver);
    timeoutMouseOver = window.setTimeout(() => {
      columns.forEach((col) => {
        const list = col.childNodes.item(1);
        if (list) {
          const li = list.childNodes.item(idx);
          if (li) {
            li.classList.add("hover");
            // li.setAttribute("aria-checked", "true");
            if (liHovered !== idx) {
              liHovered = idx;
            }
          }
        }
      });
    }, 100);

  }

  const handleLiMouseOut = (e, idx) => {
    if (!columns || columns.length !== 6) {
      columns = e.target.parentNode.parentNode.parentNode.childNodes;
      return;
    }
    if (liHovered === null) {
      clearTimeout(timeoutMouseOver);
      return;
    }
    columns.forEach((col) => {
      const list = col.childNodes.item(1);
      if (list) {
        if (idx !== liHovered) {
          const li = list.childNodes.item(idx);
          if (li) {
            li.classList.remove("hover");
            // li.setAttribute("aria-checked", "false");
          }
        }

        const li = list.childNodes.item(idx);
        if (li) {
          li.classList.remove("hover");
          // li.setAttribute("aria-checked", "false");
          liHovered = null;
        }
      }
    });
  }

  return (
    <div className="complex-col" ref={refCol} role="tab" tabIndex={tabIndex}>
      <div className="complex-col_title">
        <p className="col-title h6">
          {title}
          <small className="col-title_time">Время выполнения <br/>{time}</small>
          {
            hit && <span className="col-title_hit">хит</span>
          }
        </p>


        <div className="col-price">
          <ComplexColumnPrice
            activePriceKey={activePriceKey}
            price={price}
            changeActivePriceKey={changeActivePriceKey}
            complexTitle={title}
          />
          <div className="payments">
            <img src={SvgMastercard} alt={"мастеркард mastercard"} height={32}/>
            <img src={SvgVisa} alt={"виза visa"} height={12}/>
          </div>
          <div className="complex-btn">
            <ComplexColumnModal title={title}>
              <Button size="sm" variant="primary">Записаться</Button>
            </ComplexColumnModal>
          </div>
        </div>


      </div>

      {/* какого-то хера удаляется classname в ul после build если убрать эту строку*/}
      {/*<p className="asdf"/>*/}

      <ul className="col-checklist">
        {
          complexesCheckList.map((item, i) => {
            const liClassName = bonus.indexOf(i) >= 0 ? 'bonus' : '';
            const isInvisible = checkList.indexOf(i) < 0 && bonus.indexOf(i) < 0;
            const spanClassName = isInvisible ? 'invisible' : '';
            return (
              <li key={`checklist-${title}-${i}`}
                  role="button"
                  tabIndex={0}
                  className={liClassName}
                  onMouseEnter={e => handleLiMouseOver(e, i)}
                  onMouseLeave={e => handleLiMouseOut(e, i)}
                  onFocus={e => handleLiMouseOver(e, i)}
                  onBlur={e => handleLiMouseOut(e, i)}
              >
                <span className={spanClassName}>{item.title}</span>
                {
                  !isInvisible && item.popover &&
                  <ComplexColumnHelpButton popoverBody={item.popover} complexTitle={item.title}/>
                }
              </li>
            )
          })
        }
      </ul>

    </div>
  )
}


ComplexColumn.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  bonus: PropTypes.arrayOf(PropTypes.number).isRequired,
  hit: PropTypes.bool,
  price: PropTypes.shape({
    sedan: PropTypes.number.isRequired,
    suv: PropTypes.number.isRequired,
    van: PropTypes.number.isRequired,
  }).isRequired,
  activePriceKey: PropTypes.oneOf(["sedan", "suv", "van"]).isRequired,
  changeActivePriceKey: PropTypes.func.isRequired,
  checkList: PropTypes.arrayOf(PropTypes.number).isRequired,
}