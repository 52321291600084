import * as React from "react";
import {complexesList, trackEvent} from "../common";
import {ComplexColumn} from "./ComplexColumn";
import Container from "react-bootstrap/Container";

function ComplexColumns() {
  const ref = React.useRef(null);
  const [priceKey, setPriceKey] = React.useState('sedan');

  let isActive = false;
  let startX = 0;
  let scrollLeft = 0;

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollLeft = 540 - ref.current.clientWidth / 2;
      // console.log(ref.current.childNodes[0].childNodes[0].style.top = "100px")
      window.addEventListener("scroll", handleWindowVerticalScroll);
    }
    return () => {
      window.removeEventListener("scroll", handleWindowVerticalScroll);
    }
  }, []);


  const handleWindowVerticalScroll = () => {
    const hasShadow = ref.current.classList.contains("shadow");
    if (window.innerHeight < 640) {
      if (hasShadow) {
        ref.current.classList.remove("shadow");
        ref.current.childNodes.forEach((node) => node.childNodes.item(0).style.top = "0px");
      }
      return;
    }

    const rect = ref.current.getBoundingClientRect()
    let threshold = 0;
    if (window.innerWidth <= 991) {
      threshold = 55;
    } else if (window.innerWidth > 991 && window.innerWidth < 1200) {
      threshold = 24;
    }
    //
    if (rect.top < threshold) {
      if (!hasShadow) {
        ref.current.classList.add("shadow");
      }
      if (rect.top > -600) {
        ref.current.childNodes.forEach((node) => node.childNodes.item(0).style.top = -rect.top - 2 + threshold + "px")
      }
    } else {
      if (hasShadow) {
        ref.current.classList.remove("shadow");
        ref.current.childNodes.forEach((node) => node.childNodes.item(0).style.top = "0px");
      }
    }
  }

  const handleMouseDown = (e) => {
    isActive = true;
    const target = e.currentTarget;
    startX = e.pageX - target.offsetLeft;
    scrollLeft = target.scrollLeft;
  };

  const handleMouseUp = (e) => {
    isActive = false;
  };

  const handleMouseLeave = (e) => {
    isActive = false;
  };

  const handleMouseMove = (e) => {
    if (!isActive) return;
    e.preventDefault();

    const target = e.currentTarget;
    target.scrollLeft = scrollLeft - (e.pageX - target.offsetLeft - startX) * 1.1;
  };

  const handleTouchStart = (e) => {
    isActive = true;
  };

  const handleTouchEnd = (e) => {
    isActive = false;
  };

  const changeActivePriceKey = (key) => {
    if (["sedan", "suv", "van"].indexOf(key) < 0) return;
    if (priceKey === key) return;
    setPriceKey(key);
    trackEvent("Price", "Select", key, true);
  }

  return (
    <div className="complex-columns-wrapper">
      <Container>
        <p>* Комплексы услуг разработаны специалистами Autozorgo для оптимизации цены и качества</p>
      </Container>

      <div className="complex-columns"
           ref={ref}
           role="tablist"
           tabIndex={0}
           onMouseDown={handleMouseDown}
           onMouseUp={handleMouseUp}
           onMouseLeave={handleMouseLeave}
           onMouseMove={handleMouseMove}
           onTouchStart={handleTouchStart}
           onTouchEnd={handleTouchEnd}
      >
        {
          complexesList.map((complex, i) => {
            return (
              <ComplexColumn
                tabIndex={i}
                time={complex.time}
                price={complex.price}
                title={complex.title}
                key={`complex-${i}`}
                checkList={complex.checkList}
                bonus={complex.bonus}
                hit={i === 2}
                activePriceKey={priceKey}
                changeActivePriceKey={changeActivePriceKey}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default ComplexColumns;