import React from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import OkleikaPlenkoiIconImage from "../images/complex-okleika-plenkoi_icon.jpg";
import OkleikaPlenkoiIconImageWebp from "../images/complex-okleika-plenkoi_icon.jpg.webp";
import HimchistkaIconImage from "../images/himchistka_icon.jpg";
import HimchistkaIconImageWebp from "../images/himchistka_icon.jpg.webp";
import { Link } from 'gatsby';

export const ServiceNav = () => {
    return (
      <div className="service-nav">
        <Container>
          <p className='h2 mb-5'>Дополнительные услуги</p>
        </Container>
        
        <Container>
          <Row>
            <Col xs={12} lg={6} className='mb-4'>
              <Link className='service-nav__item' to='/okleika-plenkoi/'>
                <picture>
                  <source srcSet={OkleikaPlenkoiIconImageWebp} type="image/webp" />
                  <source srcSet={OkleikaPlenkoiIconImage} type="image/jpeg" />
                  <img src={OkleikaPlenkoiIconImage} alt="оклейка автомобиля плёнкой" />
                </picture>

                <span className='service-nav__item-description'>
                  <span className='service-nav__item-header'>
                    Оклейка автомобиля антигравийной плёнкой
                  </span>

                  Защита от сколов и царапин, химических реагентов, ультрафиолета и других проблем.<br/>
                  <strong>Официальная гарантия</strong>
                </span>
              </Link>
            </Col>

            <Col xs={12} lg={6} className='mb-4'>
              <Link className='service-nav__item' to='/himchistka/'>
                <picture>
                  <source srcSet={HimchistkaIconImageWebp} type="image/webp" />
                  <source srcSet={HimchistkaIconImage} type="image/jpeg" />
                  <img src={HimchistkaIconImage} alt="химчистка автомобилей" />
                </picture>

                <span className='service-nav__item-description'>
                  <span className='service-nav__item-header'>
                    Химчистка автомобиля
                  </span>

                  Глубокая очистка всех поверхностей от различных загрязнений, 
                  пятен и пыли при помощи специальных моющих средств и инструментов.
                </span>
              </Link>
            </Col>       
          </Row>
        </Container>
      </div>
    )           
}