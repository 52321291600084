import * as React from "react";
import HeaderWide from "./HeaderWide";
import HeaderSmall from "./HeaderSmall";

const Header = () => {
  return (
    <>
      <HeaderWide/>
      <HeaderSmall/>
    </>
  )
}

export default Header;