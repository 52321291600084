import React from "react";
import Container from "react-bootstrap/Container";
import {Instagram} from "react-bootstrap-icons";
import TrackLink from "./TrackLink";
import LogoSvg from "../images/logo.svg";
import HeaderContacts from "./HeaderContacts";

function HeaderWide() {
  return (
    <header className="header-wide">
      <div className="instagram">
        <Container>
          <TrackLink href={"https://www.instagram.com/autozorgo_detailing/"}
                     track={["Social", "Click", "Instagram", true]}
                     targetBlank
          >
            Посмотреть наш {" "}
            <Instagram/> Instagram
          </TrackLink>
        </Container>
      </div>

      <Container>
        <div className={"header-inner"}>
          <div className={"header-logo"}>
            <img src={LogoSvg} alt={"Логотип Autozorgo"}/>
          </div>

          <div className={"header-intro"}>
            <p className={"h6"}>
              Студия детейлинга
              <TrackLink href={"https://www.instagram.com/autozorgo_detailing/"}
                         className={"badge rounded-pill bg-dark badge-instagram"}
                         track={["Social", "Click", "Instagram", true]}
                         targetBlank
              >
                <Instagram/> Instagram
              </TrackLink>
            </p>

            <p>
              Делаем детейлинг автотранспорта
              с учетом особенностей погодных условий текущего сезона
            </p>
          </div>

          <HeaderContacts/>
        </div>
      </Container>
    </header>)
}
export default HeaderWide;