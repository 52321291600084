import React from "react";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import SvgSedan from "../images/sedan_dark.svg";
import SvgSuv from "../images/suv_dark.svg";
import SvgVan from "../images/van_dark.svg";
import {trackEvent} from "../common";

function ComplexColumnPrice({price, activePriceKey, changeActivePriceKey, complexTitle}) {
  const activePrice = price[activePriceKey];
  let activeImage;
  if (activePriceKey === "sedan") {
    activeImage = SvgSedan;
  }
  if (activePriceKey === "suv") {
    activeImage = SvgSuv;
  }
  if (activePriceKey === "van") {
    activeImage = SvgVan;
  }

  const handleToggle = (isOpen) => {
    if (!isOpen) return;
    trackEvent("Price", "Click", complexTitle, true);
  }

  return (
    <>
      <Dropdown onSelect={key => changeActivePriceKey(key)} onToggle={handleToggle}>
        <Dropdown.Toggle>
            <span className={"dropdown-button_inner"}>
            <img src={activeImage} alt=""/>
            <span>
              <b>{activePrice}</b>{" руб."}
            </span>
            </span>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ margin: 0 }}>
          <Dropdown.Item eventKey="sedan">
            <span className="dropdown-menu_item">
              <img src={SvgSedan} alt={"sedan"}/>
              <span>
                <b>{price.sedan}</b>{" руб."}
              </span>
            </span>
          </Dropdown.Item>

          <Dropdown.Item eventKey="suv">
            <span className="dropdown-menu_item">
              <img src={SvgSuv} alt={"suv"}/>
              <span>
                <b>{price.suv}</b>{" руб."}
              </span>
            </span>
          </Dropdown.Item>

          <Dropdown.Item eventKey="van">
            <span className="dropdown-menu_item">
              <img src={SvgVan} alt={"van"}/>
              <span>
                <b>{price.van}</b>{" руб."}
              </span>
            </span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <p className="dropdown-tip">* выбрать тип автомобиля</p>
    </>
  )
    ;
}

export default ComplexColumnPrice;

ComplexColumnPrice.propTypes = {
  price: PropTypes.shape({
    sedan: PropTypes.number.isRequired,
    suv: PropTypes.number.isRequired,
    van: PropTypes.number.isRequired,
  }).isRequired,
  activePriceKey: PropTypes.oneOf(["sedan", "suv", "van"]).isRequired,
  changeActivePriceKey: PropTypes.func.isRequired,
  complexTitle: PropTypes.string.isRequired,
}