import React from "react";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import {BoxArrowUpRight} from "react-bootstrap-icons";
import FormCallOrder from "./FormCallOrder";
import {contacts, trackEvent} from "../common";
import TrackLink from "./TrackLink";

function ComplexColumnModal({children, title}) {
  const [show, setShow] = React.useState(false);

  const showModal = () => {
    setShow(true);
    trackEvent("Modal", "Show", title, true);
  }

  return (
    <>
      {React.cloneElement(children, {onClick: showModal})}
      <Modal show={show} onHide={() => setShow(false)} className={"complex-column-modal"}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="complex-column-modal_call-order-wrapper">

          <FormCallOrder subject={title} label={"Оставьте свой номер телефона"} inline/>
          <Alert>
            С вами свяжется администратор для консультации или согласования времени обслуживания.
            <strong className={"d-block"}>
              Номер телефона администратора: {" "}
              <TrackLink href={contacts.phone[0].href}
                         track={["Phone", "Click", contacts.phone[0].value]}
              >
                {contacts.phone[0].value}
              </TrackLink>
            </strong>
          </Alert>
          </div>

          <TrackLink href="https://n378531.yclients.com/"
                     className={"btn btn-outline-primary btn-icon btn-icon_text btn-icon_text_right d-block"}
                     track={["Link", "Click", "Записаться онлайн"]} targetBlank
          >
            Записаться онлайн
            <BoxArrowUpRight/>
          </TrackLink>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ComplexColumnModal;

ComplexColumnModal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired
}