import React from "react";
import {contacts, trackEventWrap} from "../common";
import {Link} from "gatsby";
import TrackLink from "./TrackLink";
import ModalFormCallOrder from "./ModalFormCallOrder";
import Button from "react-bootstrap/Button";

function HeaderContacts() {
  return (
    <div className={"header-contacts"}>
      <ul className={"contacts_address"}>
        <li>
          <Link to="#yandex-map" onClick={trackEventWrap("ScrollToYandexMap", "Click", "", true)}>
            {contacts.address.city}, {contacts.address.address}
          </Link>
        </li>
        <li>{
          contacts.workHours.map((h, i) => {
            return <li key={`header-workhours-${i}`}>{h}</li>
          })
        }</li>
      </ul>

      <ul className={"contacts_phones"}>
        {
          contacts.phone.map((phone, i) => {
            return <li key={`phone-${i}`}>{
              <TrackLink {...phone} key={`phone-${i}`} track={["Phone", "Click", phone.value]}/>
            }</li>
          })
        }
        <li>
          <ModalFormCallOrder subject={"Без указания комплекса услуг"}>
            <Button>Заказать звонок</Button>
          </ModalFormCallOrder>
        </li>
      </ul>
    </div>
  )
}

export default HeaderContacts;